import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../shared/interfaces';
import { filterOutNullishProperties } from '../shared/utils';
import { ScheduleResponse } from '../modules/shared/interfaces/responses';
import { ScheduleType } from '../shared/enums';
import { MekiPodType } from '../modules/shared/enums';

@Injectable()
export class MekiService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Returns a sub-set of a full SEK schedule that the current user is allowed to see.
   *
   * @param tenant The unique identifier of a tenant.
   * @param params It contains the type, the date, the version of the schedule, a POD code and a partner EIC code.
   */
  public getSekSchedule(
    params: {
      scheduleType: ScheduleType;
      businessDay: string;
      version: 'cnf' | 'edge' | number;
      podCodes?: string[];
      partnerEicCodes?: string[];
      podType?: MekiPodType[];
      noFilter?: boolean;
      order?: boolean;
    },
    tenant?: string
  ): Observable<ScheduleResponse> {
    return this.http
      .get<ApiResponse<ScheduleResponse>>(
        `${environment.apiBasePath}/v1/meki/schedules/SEK/${params.scheduleType}/${params.businessDay}/${params.version}?`,
        {
          params: filterOutNullishProperties({
            tenant,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            pod_code: params.podCodes,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            partner_eic_code: params.partnerEicCodes,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            pod_type: params.podType,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            no_filter: params.noFilter,
            order: params.order,
          }),
        }
      )
      .pipe(map(response => response.payload));
  }
}
