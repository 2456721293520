/**
 * Describes which ruling entity the pricing strategy applies to.
 */
export enum EntityEnum {
  ASZK = 'ASZK',
  ASZKM1 = 'ASZKM1',
  NYPAZPV = 'NYPAZPV',
  MOLFPV = 'MOLFPV',
  TMPKPV = 'TMPKPV',
  FCR = 'FCR',
}
