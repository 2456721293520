import { Component, DestroyRef, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { BannerContainerComponent } from '../../../../modules/shared/components';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BossModuleAccess, BossModuleName } from '../../../../shared/enums';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntityEnum } from '../../../../modules/boss/enums';
import { EdaPricingStrategyService } from '../../../../modules/boss/services';
import { catchError, filter, Observable, of, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';
import { BossAccessService } from '../../../../services';

@Component({
  selector: 'app-assign-tenant-boss-access-dialog',
  templateUrl: './assign-tenant-boss-access-dialog.component.html',
  styleUrls: ['./assign-tenant-boss-access-dialog.component.scss'],
  standalone: false,
})
export class AssignTenantBossAccessDialogComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  /** Indicates if the request is currently in progress or not. */
  public requestInFlight = false;

  @ViewChild(BannerContainerComponent, { static: true })
  public bannerContainer!: BannerContainerComponent;

  /** Available data for the selectors. */
  public availableAllBossModuleNames = Object.values(BossModuleName);
  public availableBossModuleNames: BossModuleName[] = [];
  public availableAllEntities: EntityEnum[] = [];
  public availableEntitiesForRszModule = [EntityEnum.ASZK, EntityEnum.ASZKM1, EntityEnum.FCR];
  public availableEntities = this.availableEntitiesForRszModule;
  public availableBossModuleAccessLevels = Object.values(BossModuleAccess);

  public readonly formGroup = new FormGroup({
    module: new FormControl<BossModuleName | null>(null, Validators.required),
    entity: new FormControl<string | null>(null, Validators.required),
    access: new FormControl<BossModuleAccess>(this.availableBossModuleAccessLevels[0], {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<AssignTenantBossAccessDialogComponent, { success: boolean }>,
    @Inject(MAT_DIALOG_DATA)
    public data: { tenantIdentifier: string; entryMap: Map<string, Set<string>> },
    private readonly edaPricingStrategyService: EdaPricingStrategyService,
    private readonly bossAccessService: BossAccessService
  ) {}

  public ngOnInit(): void {
    this.edaPricingStrategyService
      .getEdaConfiguration()
      .pipe(
        catchError(this.handleError.bind(this)),
        filter(result => result !== undefined),
        tap(result => {
          this.availableAllEntities = result.entityConfigurations.map(
            entityConfiguration => entityConfiguration.entity as EntityEnum
          );

          this.availableAllBossModuleNames.forEach(bossModuleName => {
            const selectedEntry = this.data.entryMap.get(bossModuleName);

            if (selectedEntry === undefined) {
              if (this.formGroup.controls.module.value === null) {
                this.formGroup.controls.module.setValue(bossModuleName);
                this.availableEntities =
                  bossModuleName === BossModuleName.RSZ
                    ? this.availableEntitiesForRszModule
                    : this.availableAllEntities;
                this.formGroup.controls.entity.setValue(this.availableEntities[0]);
              }

              this.availableBossModuleNames.push(bossModuleName);
            } else {
              const filteredEntities = (
                bossModuleName !== BossModuleName.RSZ ? this.availableAllEntities : this.availableEntitiesForRszModule
              ).filter(entity => !selectedEntry.has(entity));

              if (this.formGroup.controls.module.value === null) {
                this.availableEntities = filteredEntities;
              }

              if (filteredEntities.length !== 0) {
                if (this.formGroup.controls.module.value === null) {
                  this.formGroup.controls.module.setValue(bossModuleName);
                  this.formGroup.controls.entity.setValue(this.availableEntities[0]);
                }

                this.availableBossModuleNames.push(bossModuleName);
              }
            }
          });
        })
      )
      .subscribe();

    this.formGroup.controls.module.valueChanges
      .pipe(
        tap(module => {
          const selectedEntry = this.data.entryMap.get(module as BossModuleName);
          this.availableEntities = (
            module === BossModuleName.RSZ
              ? this.availableEntitiesForRszModule
              : this.availableAllEntities.filter(entity => entity !== EntityEnum.FCR)
          ).filter(entity => !selectedEntry?.has(entity));
          this.formGroup.controls.entity.setValue(this.availableEntities[0]);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  /** Assigns the selected BOSs access to a tenant and closes the dialog. */
  public handleAcceptAction(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.bannerContainer.clearAll();
    this.requestInFlight = true;

    this.bossAccessService
      .assignBossAccess(this.data.tenantIdentifier, {
        entity: this.formGroup.controls.entity.value as string,
        module: this.formGroup.controls.module.value as BossModuleName,
        access: this.formGroup.controls.access.value,
      })
      .pipe(
        catchError(this.handleError.bind(this)),
        tap(result => {
          this.requestInFlight = false;

          if (result !== undefined) {
            this.dialogRef.close({ success: true });
          }
        })
      )
      .subscribe();
  }

  /** Closes the dialog without executing the action. */
  public handleCancelAction(): void {
    this.dialogRef.close({ success: false });
  }

  /** Handles the displaying of error messages. */
  private handleError(errorResponse: HttpErrorResponse): Observable<undefined> {
    this.requestInFlight = false;

    this.bannerContainer.showApiError(errorResponse.error as Error, {
      closable: true,
    });

    return of(undefined);
  }
}
